import React, { useState, useEffect } from "react";
import { Grid, Card } from "@material-ui/core";
import DataService from "../../services/api/data_services";
import Layout from "../../components/layouts/layout";
import MobileSmoothSlider from "../../components/MobileSmoothSlider";
import Infoview from "../../templates/InfoView";
import Megaview from "../../templates/MegaView";
import Miniview from "../../templates/MiniView";
import Button from "../../components/Button";
import RightArrow from "../../assets/Icons/go.svg";
import clutchLogo from "../../assets/Images/WhatWeDo/clutch-co-vector-logo.svg";
import RightArrowWhite from "../../assets/Icons/accordian_white.svg";
// import LeftGrid from "../../templates/LeftGrid";
import { BASE_SITE_URL } from "../../shared/constants";
//Image Banner
import ImageBanner from "../../templates/ImageBanner";
import MobileMovieSlider from "../../components/MobileMovieSlider";
//Image Banner../../assets/Images/Industry/Education/OurDigitalEducationcapabilitiesIncludes@2x.png
import ourTraits from "../../assets/Images/Industry/Education/OurDigitalEducationcapabilitiesIncludes@2x.png";
//Icon Card List
import IconCardList from "../../templates/IconCardList";
import IconCardListIcon1 from "../../assets/Images/Industry/Education/Augmented and Virtual Reality (AR_VR).svg";
import IconCardListIcon2 from "../../assets/Images/Industry/Education/Addressing Disruption.svg";
import IconCardListIcon3 from "../../assets/Images/Industry/Education/Massive Open Online Courses (MOOCs).svg";
import IconCardListIcon5 from "../../assets/Images/Industry/Education/Personalised Learning.svg";
import IconCardListIcon6 from "../../assets/Images/Industry/Education/Digital School Services.svg";
import IconCardListIcon4 from "../../assets/Images/Industry/Education/Mobile first Approach.svg";
//PlainCard
import PlainCard from "../../components/SixPlainCard";
import hoverCardImg1 from "../../assets/Images/Industry/Education/Web _ Mobile Apps for Online Learning.svg";
import hoverCardImg2 from "../../assets/Images/Industry/Education/Back-Office Digital Solutions.svg";
import hoverCardImg3 from "../../assets/Images/Industry/Education/Interactive Learning with VR _ AR.svg";
import hoverCardImg4 from "../../assets/Images/Industry/Education/AI _ ML Data Analytics.svg";
import hoverCardImg5 from "../../assets/Images/Industry/Education/Custom Websites _ Web Portals.svg";
import hoverCardImg6 from "../../assets/Images/Industry/Education/IT Risk Management.svg";
//Mini Image Banner1
import MiniImageBanner1 from "../../templates/MiniImageBanner1";
import MiniImageBannerBgImg from "../../assets/Images/WhatWeDo/who_we_work_with.png";

//Mini Image Banner
import MiniImageBanner from "../../templates/MiniImageBanner";
import MiniImgBnrBgImg from "../../assets/Images/contact_us_bg.png";
import Typography from "@material-ui/core/Typography";
import BackArrowIcon from "../../assets/Images/accordian.svg";

//Two column contact info
import TwoColumnContactInfo from "../../templates/TwoColumnContactInfo";
import Clm1Img from "../../assets/Icons/phone.svg";
import Clm2Img from "../../assets/Icons/mail.svg";

import ogimage from "../../assets/Images/ogImages/education@2x.png";
import ImageBnr from "../../assets/Images/Industry/Education/Education@2x.png";
import scrollImage from "../../assets/Images/Industry/Education/OurRetailAndE-commerceexpertiseCreates@2x.png";

const Education = () => {
  const [page, setPage] = useState(1);
  const [articleData, setArticleData] = useState([]);
  const [megaviewData, setMegaViewData] = useState([]);
  const [clientStoryData, setClientStoryData] = useState([]);
  const [clientStoryRawData, setClientStoryRawData] = useState([]);
  const [articleRawData, setArticleRawData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const [articlePage, setArticlePage] = useState(1);
  const [articleLimit, setArticleLimit] = useState(6);
  const [whitePaperRawData, setWhitePaperRawData] = useState([]);
  const [whitePaperPage, setWhitePaperPage] = useState(1);
  const [whitePaperLimit, setWhitePaperLimit] = useState(1);

  const toggleHover = () => {
    setIsHover(!isHover);
  };

  useEffect(() => {
    DataService.getData(
      `/admin/api/all-industries?limit=${whitePaperLimit}&page=${whitePaperPage}&type=White Paper&industry_type=Education`
    ).then((response) => {
      setMegaViewData([...megaviewData, ...response.data]);
      setWhitePaperRawData(response);
    });
  }, [whitePaperPage]);

  useEffect(() => {
    DataService.getData(
      `/admin/api/all-industries?limit=3&page=${articlePage}&type=Article&industry_type=Education`
    ).then((response) => {
      setArticleData([...articleData, ...response.data]);
      setArticleRawData(response);
    });
  }, [articlePage]);
  useEffect(() => {
    DataService.getData(
      `/admin/api/all-industries?limit=3&page=${page}&type=Client Story&industry_type=Education`
    ).then((response) => {
      setClientStoryData([...clientStoryData, ...response.data]);
      setClientStoryRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const quotedMiniCardData = {
    title:
      "Helping Coaching service provider of fortune 500 companies to digitise their operations",
    heading: "If I could describe them in one word, it’d be cooperative.",
    content: "- Partner, Service Industry",
    footerContent: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>Authentic client review from</div>
        <div style={{ paddingLeft: "5px" }}>
          <img src={clutchLogo} alt="Clutch" height="50px" />
        </div>
      </div>
    ),
  };

  const miniviewDataArticle = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };

  /**
   * Image Banner // HIDING TEMPORATILY
   */
  const ImageBannerData = {
    bnrImg: ImageBnr,
    altImg: ImageBnr,
    // component:'Who',
    bnrImgTitle: <> Education</>,
    bnrImgSubTitle: "Industries",
    servicesColumn1: {
      data: [
        {
          service: "Startups",
          link: "/who-we-work-with/startups",
        },
        {
          service: "Education",
          link: "/who-we-work-with/education",
        }, // this is temporary
        // {
        //   service: "Finance",
        //   link: "/who-we-work-with/finance"
        // },
        // {
        //   service: "Hospitality",
        //   link: "/who-we-work-with/hospitality"
        // },
        // {
        //   service: "Not-For-Profit",
        //   link: "/who-we-work-with/not-for-profit"
        // }
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "Healthcare",
          link: "/who-we-work-with/healthcare",
        },
        {
          service: "Finance",
          link: "/who-we-work-with/finance",
        }, // this is temporary
        // {
        //   service: "Education",
        //   link: "/who-we-work-with/education"
        // },
        // {
        //   service: "Real Estate & PropTech",
        //   link: "/who-we-work-with/real-estate-and-proptech"
        // },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Retail and E-commerce",
          link: "/who-we-work-with/retail-and-e-commerce",
        },
        // {
        //   service: "Media and Publishing",
        //   link: "/who-we-work-with/media-and-publishing"
        // },
        // {
        //   service: "Public Sector",
        //   link: "/who-we-work-with/public-sector"
        // }
      ],
    },
  };
  /**
   * Mini Image Banner
   */

  const miniImageBannerData = {
    Id: "minibanner1",
    MiniImageBannerBgImg: MiniImgBnrBgImg,
    MiniImageBannerAltTxt: "Contact Us to estimate your product",
    HoverLinkContent: "Contact Us",
    NearHoverLinkContent: " to ",
    PlainContent: "estimate your product",
    route: "/contact-us",
  };
  const InfoviewData = {
    route: "/what-we-do",
    color: "primary",
    tooltip: "yes",
    width: "11%",
    tag: "h2",
    InfoviewTitle: (
      <div className="ttc">
        {" "}
        <span className="red-line3letter red-3letter-mbl">Edu</span>cation
      </div>
    ),
    InfoviewContent: (
      <>
        The use of the cloud in the education industry has completely
        revolutionised the way it operates. No longer is it difficult for anyone
        across the globe to access any informative data. Since the rise of the
        internet, e-Learning products and services have gained large demand from
        customers ranging from private enterprises to schools, universities and
        government agencies. People who are geographically spread across
        different continents are taking advantage of enormous opportunities
        offered by combining learning and training materials with the internet
        and optimising their operations beyond boundaries. Digiryte enhances the
        e-learning experience by combining appropriate technologies and analysis
        making the experience more lively for your customers and profitable for
        your business.
      </>
    ),
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };
  /**
   * Hover card button
   */

  const PlainCardData = [
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Web & Mobile Apps for Online Learning",
      HoverCardWithBtnsubtitle: " ",
      HoverCardWithBtnIcon: hoverCardImg1,
      HoverCardWithBtnIconAlt: "Web & Mobile Apps for Online Learning",
      HoverCardIconClass: "plain-card-button-main-img",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Custom Websites & Web Portals",
      HoverCardWithBtnIcon: hoverCardImg5,
      HoverCardWithBtnIconAlt: "Custom Websites & Web Portals",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: " ",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Interactive Learning with VR & AR",
      HoverCardWithBtnIcon: hoverCardImg3,
      HoverCardWithBtnIconAlt: "Interactive Learning with VR & AR",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: " ",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "AI & ML Data Analytics",
      HoverCardWithBtnIcon: hoverCardImg4,
      HoverCardWithBtnIconAlt: "AI & ML Data Analytics",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: " ",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Back-Office Digital Solutions",
      HoverCardWithBtnIcon: hoverCardImg2,
      HoverCardWithBtnIconAlt: "Back-Office Digital Solutions",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: " ",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "IT Risk Management",
      HoverCardWithBtnIcon: hoverCardImg6,
      HoverCardWithBtnIconAlt: "IT Risk Management",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: " ",
    },
  ];
  //  Mobile movie slider
  const mobMovieSlider = {
    imageUrl: ourTraits,
  };

  //Movie Slider
  const movieSlider = {
    imageUrl: scrollImage,
  };
  /**
   * Icon Card List Data
   */

  const IconCardListData = [
    {
      icon: IconCardListIcon1,
      altTxt: "Augmented and Virtual Reality (AR/VR)",
      heading: (
        <>
          Augmented and <br /> Virtual Reality <br /> (AR/VR)
        </>
      ),
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon2,
      altTxt: "Addressing Disruption",
      heading: (
        <>
          Addressing <br /> Disruption
        </>
      ),
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon3,
      altTxt: "Massive Open Online Courses (MOOCs)",
      heading: (
        <>
          Massive Open Online <br /> Courses (MOOCs)
        </>
      ),
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon6,
      altTxt: "Personalised Learning",
      heading: <>Personalised Learning</>,
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon5,
      altTxt: "Digital School Services",
      heading: <>Digital School Services</>,
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon4,
      altTxt: "Mobile first Approach",
      heading: (
        <>
          Mobile first <br /> Approach
        </>
      ),
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
  ];
  const MiniImageBanner1Data1 = {
    title: (
      <span>
        <span className="black-line3letter">How</span> we do it
      </span>
    ),
    titleClass: "mini-image-banner-title-area3 min-title-header",
    image: MiniImageBannerBgImg,
    altTxt: "How we do it",
    btnClassName: "image-banner-resize-btn",
    btnLabel: "TAKE A LOOK",
    btnVariant: "contained",
    btnColor: "primary",
    route: "/how-we-do-it",
    underline: false,
    tooltip: "yes",
    tip: "TAKE A LOOK",
    underlineClass: "black-line",
  };
  const MiniImageBanner3Data = {
    Id: "minibanner1",
    MiniImageBannerBgImg: MiniImgBnrBgImg,
    MiniImageBannerAltTxt: "Contact Us to estimate your product",
    HoverLinkContent: "Contact Us",
    NearHoverLinkContent: " to ",
    PlainContent: "estimate your product",
    route: "/contact-us",
  };
  return (
    <Layout
      seo={{
        title: "Digiryte | Education | Learning Management System",
        ogtitle: "Digiryte | Education | Learning Management System",
        description:
          "With enhanced e-learning experience by combining technologies and analysis making the experience more lively for your customers and profitable for your business.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}`,
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-70 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="What we do"
          routepath="/who-we-work-with"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          Who we work with
        </Typography>
      </div>
      <div className="mt-30">
        <Infoview InfoviewData={InfoviewData} />
      </div>

      <div className="body-container mt-70">
        <h2 className="title-header">
          Our Digital Education
          <br /> <span className="red-line3letter red-3letter-mbl">Exp</span>
          ertise Includes
        </h2>
      </div>
      <Grid
        className="mt-50 body-container hide-in-mobile"
        container
        direction="row"
        spacing={3}
      >
        {PlainCardData.map((item, index) => (
          <Grid className="appsgrid" key={index} item md={4}>
            <Card className="appsgridshadow">
              <PlainCard
                customHeight="140"
                HoverCardWithBtnCustomClass={item.HoverCardWithBtnCustomClass}
                HoverCardWithBtntitle={item.HoverCardWithBtntitle}
                HoverCardWithBtnIcon={item.HoverCardWithBtnIcon}
                HoverCardWithBtnIconAlt={item.HoverCardWithBtnIconAlt}
                HoverCardIconClass={item.HoverCardIconClass}
                HoverCardWithBtnsubtitle={item.HoverCardWithBtnsubtitle}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid className="mt-50 show-only-in-mobile">
        <MobileSmoothSlider movieSlider={movieSlider} />
      </Grid>
      <div className="mt-50">
        <MiniImageBanner miniImageBannerData={MiniImageBanner3Data} />
      </div>
      <div className="body-container mt-70">
        <h3 className="title-header">
          Our Digital Education Capabilities <br />{" "}
          <span className="red-line3letter red-3letter-mbl">Inc</span>ludes
        </h3>
        <div className="mt-50 mbl-hide">
          <IconCardList IconCardListData={IconCardListData} />
        </div>
        <MobileMovieSlider movieSlider={mobMovieSlider} slideid="slide1" />
      </div>
      {/* {clientStoryData && clientStoryData.length > 0 && (   <div className="mt-70 body-container">
        <h3 className="title-header"> <span className="red-line3letter red-3letter-mbl">Cli</span>ent Stories</h3>  
        <div className="mt-50"></div>
    
      </div>)}
      { clientStoryData && clientStoryData.length > 0 && (  <div className="mt-50">
                <Miniview miniviewData={clientStoryData} miniviewData2={miniviewDataArticle} quotedMiniCardData={quotedMiniCardData} navigateComponent={<LeftGrid />} />
      </div>)}
      {clientStoryRawData.has_more &&   <div style={{ width: "100%", textAlign: "center" }}>
        <div onMouseEnter={toggleHover} onMouseLeave={toggleHover} className="mt-60" role="presentation" onClick={() => {setPage(page + 1); }} style={{display: 'inline-block'}}>
       <Button
             icon={
             (
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE CLIENT STORIES"
                />
                
              )
            }
            customClassName="info-view-resize-btn"
            color="primary"
            variant="outlined"
            label="MORE CLIENT STORIES"
            tip="MORE CLIENT STORIES"
            tooltip='yes'
          />
        </div>
      </div>} */}
      {megaviewData && megaviewData.length > 0 && (
        <div className="mt-70 body-container">
          <Megaview MegaviewData={megaviewData} />
        </div>
      )}
      {whitePaperRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setWhitePaperPage(whitePaperPage + 1);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="LOAD MORE"
              tip="LOAD MORE"
            />
          </div>
        </div>
      )}
      <div className="mt-70"></div>
      <div className="mt-20">
        <MiniImageBanner1
          id="one"
          MiniImageBanner1Data={MiniImageBanner1Data1}
        />
      </div>
      {articleData && articleData.length > 0 && (
        <div className="mt-70">
          <div className="body-container">
            <h3 className="title-header ttc">
              <span className="red-line3letter">Rec</span>ommended Reading
            </h3>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={articleData}
              total={articleData.total}
              miniviewData2={miniviewDataArticle}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setArticlePage(articlePage + 1);
              setArticleLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="LOAD MORE"
              tip="LOAD MORE"
            />
          </div>
        </div>
      )}
      <div>
        <TwoColumnContactInfo
          TwoColumnContactInfoData={TwoColumnContactInfoData}
        />
      </div>
    </Layout>
  );
};

export default Education;
