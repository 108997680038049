import { Grid } from "@material-ui/core";
import React from "react";
import IconCard from "../../components/IconCard";


const IconCardList = (props) => {
  return (
    <Grid container direction="row" spacing={10}>
      {props.IconCardListData.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <IconCard
            icon={item.icon}
            altTxt={item.altTxt}
            heading={item.heading}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default IconCardList;
